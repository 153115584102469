import React, { useState, useContext } from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"
import styled from "styled-components"
import { navigate } from "gatsby"
import { H2 } from "../../styled/headers"
import { useBasicQuery } from "@useBasicQuery"
import { breakpoints } from "@breakpoints"
import { routes } from "@routes"
import { setLocalStorage } from "../../../utils/localStorage"
import { USER_ORDER, SINGLE_GADGET } from "../../../consts/queries"
import axios from "../../../utils/axios"
import Spinner from "../../common/Spinner"

import Amount from "./Amount"
import { Button } from "../../common/Button"
import { UserContext } from "../../context/UserContext"
import { ModalContext } from "../../context/ModalContext"
import { gatsbyImage } from "@types"
import { calculateStock } from "../../../utils/calculateStock"

const StyledPrice = styled.div`
    color: var(--dark-blue);
    font-size: var(--big-font);
    font-weight: 700;

    span {
        color: var(--medium-blue);
    }

    ${breakpoints.md} {
        font-size: 40px;
    }
`

const StyledContent = styled.div`
    margin: 20px 0 40px;
    font-size: var(--base-font);

    ${breakpoints.md} {
        margin: 20px 0 60px;
    }
`

const StyledBuy = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
`

const StyledError = styled.div`
    font-weight: 700;
    color: var(--red);
    margin-top: 10px;
`

const StyledItalic = styled.span`
    display: flex;
    font-size: var(--small-font);
    font-style: italic;
    font-weight: 300;

    ${breakpoints.sm} {
        font-size: var(--big-font);
    }
`

const Content = ({
    name,
    slug,
    image,
    content,
    price,
    amount,
    freeDelivery,
    setAmount,
}: props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const queryClient = useQueryClient()

    const {
        general: {
            acf: { price: priceLabel, buyNow },
        },
        singleproduct: {
            acf: { remainingGadgetText },
        },
    } = useBasicQuery()
    const { userData } = useContext<any>(UserContext)
    const { setNavigateAfterClosing, setModalActive } =
        useContext<any>(ModalContext)

    const { data: gadgetData } = useQuery(`${SINGLE_GADGET} - ${name}`, () =>
        axios.get(`products?slug=${slug}`)
    )
    const { isLoading: isLoadingOrder, data: userOrder } = useQuery(
        USER_ORDER,
        () => axios.get(`orders?customer=${userData.id}&status=pending`),
        {
            enabled: userData ? true : false,
        }
    )

    const addItemToOrder = () => {
        return axios.put(
            `orders/${userOrder ? userOrder.data[0].id : undefined}`,
            {
                line_items: productData,
            }
        )
    }

    const createOrder = () => {
        return axios.post("/orders", {
            currency: "PLN",
            customer_id: userData.id,
            line_items: productData,
        })
    }

    const mutation = useMutation(
        () =>
            userOrder && userOrder.data.length !== 0
                ? addItemToOrder()
                : createOrder(),
        {
            onMutate: () => {
                setLoading(true)
            },
            onSuccess: data => {
                setLocalStorage("orders", JSON.stringify(data.data))
                queryClient.invalidateQueries(USER_ORDER)
                setLoading(false)
                navigate(routes.cart)
            },
            onError: () => {
                setLoading(false)
                setError(true)
            },
        }
    )

    const productData = [
        {
            product_id: gadgetData ? gadgetData.data[0].id : null,
            quantity: amount,
            meta_data: [
                {
                    key: "slug",
                    value: `${routes.shop}/${slug}`,
                },
                {
                    key: "type",
                    value: "gadget",
                },
                {
                    key: "image",
                    value: image,
                },
                {
                    key: "free-delivery",
                    value: freeDelivery === null ? "false" : "true",
                },
            ],
        },
    ]

    const stockValue = (gadgetData: any) => {
        return calculateStock(
            gadgetData.data[0].stock_quantity,
            gadgetData.data[0].name,
            userOrder?.data[0] ? userOrder.data[0].line_items : undefined
        )
    }

    return (
        <div>
            <H2 dangerouslySetInnerHTML={{ __html: name }} />
            <StyledContent dangerouslySetInnerHTML={{ __html: content }} />
            <StyledPrice>
                <span dangerouslySetInnerHTML={{ __html: priceLabel }} />
                {` ${price}`}
            </StyledPrice>
            <StyledItalic>
                {`${remainingGadgetText} `}
                {gadgetData ? (
                    stockValue(gadgetData)
                ) : (
                    <div className="ml-1">
                        <Spinner />
                    </div>
                )}
            </StyledItalic>

            <StyledBuy>
                {gadgetData && (
                    <>
                        {stockValue(gadgetData) !== 0 && (
                            <>
                                <Amount
                                    max={stockValue(gadgetData)}
                                    amount={amount}
                                    setAmount={setAmount}
                                />
                                {isLoadingOrder ? (
                                    <Spinner />
                                ) : (
                                    <Button
                                        onClick={
                                            userData
                                                ? mutation.mutate
                                                : () => {
                                                      setModalActive(true)
                                                      setNavigateAfterClosing({
                                                          href: `${routes.shop}/${slug}`,
                                                      })
                                                  }
                                        }
                                        loading={loading}
                                        icon="cart"
                                    >
                                        {buyNow}
                                    </Button>
                                )}
                            </>
                        )}
                    </>
                )}
            </StyledBuy>
            {error && (
                <StyledError>
                    Coś poszło nie tak, produkt nie został dodany do koszyka.
                    Spróbuj ponownie za chwilę.
                </StyledError>
            )}
        </div>
    )
}

export default Content

interface props {
    name: string
    image: gatsbyImage
    slug: string
    content: string
    price: string
    amount: number
    freeDelivery: true | null
    setAmount: (number: number) => void
}
