import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    height: 200px;

    ${breakpoints.sm} {
        height: 380px;
    }

    ${breakpoints.md} {
        height: 300px;
    }
    ${breakpoints.lg} {
        height: 430px;
    }
`

const StyledActiveImage = styled.div<propsStyledActiveImage>`
    width: ${({ singleImage }) => (singleImage ? "100%" : "80%")};
    .gatsby-image-wrapper {
        height: 100%;

        picture {
            img {
                border: 1px solid var(--light-gray);
            }
        }
    }
`

const StyledSelect = styled.div<{ double: boolean }>`
    width: ${({ double }) => (double ? "40%" : "20%")};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 20px;

    > div {
        height: ${({ double }) => (double ? "48%" : "30%")};
    }

    .gatsby-image-wrapper {
        height: 100%;
        cursor: pointer;

        picture {
            img {
                border: 1px solid var(--light-gray);
            }
        }
    }
`

const Gallery = ({ images, singleImage, active, setActive }: props) => {
    return (
        <StyledWrapper>
            {!singleImage && (
                <StyledSelect double={images.length === 2}>
                    {images?.map(({ image }, index) => (
                        <div
                            key={
                                image.localFile.childImageSharp.gatsbyImageData
                            }
                            className="single-img"
                            onClick={() => setActive(index)}
                            onMouseEnter={() => setActive(index)}
                        >
                            <GatsbyImage
                                alt=""
                                imgStyle={{
                                    objectFit: "contain",
                                    width: "auto",
                                }}
                                image={
                                    image.localFile.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </div>
                    ))}
                </StyledSelect>
            )}

            <StyledActiveImage singleImage={singleImage} className="active">
                <GatsbyImage
                    imgStyle={{
                        objectFit: "contain",
                        width: "auto",
                    }}
                    alt=""
                    image={
                        singleImage
                            ? singleImage.localFile.childImageSharp
                                  .gatsbyImageData
                            : images[active].image.localFile.childImageSharp
                                  .gatsbyImageData
                    }
                />
            </StyledActiveImage>
        </StyledWrapper>
    )
}

export default Gallery

interface props {
    images: Array<{
        image: gatsbyImage
    }>
    singleImage: gatsbyImage
    active: number
    setActive: (index: number) => void
}

interface propsStyledActiveImage {
    singleImage: gatsbyImage | false
}
