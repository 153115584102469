import React, { useState } from "react"
import styled from "styled-components"

import Template from "../Template"
import Breadcrumbs from "../../common/Breadcrumbs"
import Content from "./Content"
import Gallery from "./Gallery"
import AdBanner from "../../sections/ad-banner/AdBanner"
import { GridContainer } from "../../styled/GridContainer"
import { useBasicQuery } from "@useBasicQuery"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"

const StyledBannerWrapper = styled.div`
    margin-top: 30px;

    ${breakpoints.md} {
        margin-top: 150px;
    }
`

const Gadget = ({ pageContext }: props) => {
    const [amount, setAmount] = useState(1)
    const [image, setImage] = useState(0)
    const {
        node: {
            content,
            name,
            price,
            slug,
            acf: { gallery, gadgetImage, freeDelivery },
        },
        breadcrumbs: {
            acf: { frontPage, gadgetParentPage },
        },
    } = pageContext
    const {
        singleproduct: {
            acf: { advertisements, adImage },
        },
    } = useBasicQuery()
    const images = gallery ? [{ image: gadgetImage }, ...gallery] : gadgetImage

    return (
        <Template isFrontPage={false}>
            <section className="mb-10">
                <GridContainer>
                    <Breadcrumbs
                        parentPage={{
                            name: gadgetParentPage.title,
                            slug: `/${gadgetParentPage.slug}`,
                        }}
                        frontPage={frontPage}
                        currentPage={name}
                    />
                    <div className="grid md:grid-cols-2 gap-5">
                        <Gallery
                            active={image}
                            setActive={setImage}
                            images={images}
                            singleImage={
                                !Array.isArray(images) ? gadgetImage : false
                            }
                        />

                        <Content
                            image={gadgetImage}
                            name={name}
                            slug={slug}
                            content={content}
                            price={price}
                            amount={amount}
                            freeDelivery={freeDelivery}
                            setAmount={setAmount}
                        />
                    </div>
                </GridContainer>
                <StyledBannerWrapper>
                    <AdBanner acf={{ advertisements, image: adImage }} />
                </StyledBannerWrapper>
            </section>
        </Template>
    )
}

export default Gadget

interface props {
    pageContext: {
        node: any
        breadcrumbs: {
            acf: {
                frontPage: string
                gadgetParentPage: {
                    slug: string
                    title: string
                }
                gallery: Array<gatsbyImage>
                image: gatsbyImage
                freeDelivery: true | null
            }
        }
    }
}
