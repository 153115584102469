import { breakpoints } from "@breakpoints"
import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
    display: flex;
    margin-right: 30px;
`

const StyledBox = styled.div<styledBoxProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--big-font);
    width: 30px;
    height: 40px;
    font-weight: 700;
    border: 1px var(--light-gray);
    border-style: ${({ fullBorder }) => (fullBorder ? "solid" : "solid none")};
    color: ${({ blue }) =>
        blue ? "var(--light-blue)" : "var(--medium-black)"};
    cursor: pointer;

    ${breakpoints.md} {
        width: 40px;
        height: 50px;
        font-size: var(--large-font);
    }
`

const Amount = ({ max, amount, setAmount }: props) => {
    const decreaseAmount = () => {
        if (amount !== 1) {
            setAmount(amount - 1)
        }
    }

    const increaseAmount = () => {
        if (amount + 1 <= max) {
            setAmount(amount + 1)
        }
    }
    return (
        <StyledWrapper>
            <StyledBox fullBorder onClick={() => decreaseAmount()}>
                -
            </StyledBox>
            <StyledBox blue>{amount}</StyledBox>
            <StyledBox fullBorder onClick={() => increaseAmount()}>
                +
            </StyledBox>
        </StyledWrapper>
    )
}

export default Amount

interface props {
    max: number
    amount: number
    setAmount: (number: number) => void
}

interface styledBoxProps {
    blue?: boolean
    fullBorder?: boolean
}
